import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Inicio from './pantallas/Inicio';
import Login from './pantallas/Login';
import Votar from './pantallas/Votar';
import Perfil from './pantallas/Perfil';
import Ranking from './pantallas/Ranking';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Inicio />} /> {/* Ruta para la pantalla principal */}
        <Route path="/login" element={<Login />} />
        <Route path="/votar" element={<Votar />} />
        <Route path="/perfil" element={<Perfil />} />
        <Route path="/ranking" element={<Ranking />} />
      </Routes>
    </Router>
  );
}

export default App;
