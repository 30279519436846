import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { auth, db } from "../firebase_conf";
import { collection, query, where, getDocs } from "firebase/firestore";

function Menu() {
  const [usuario, setUsuario] = useState(null);
  const [nombre, setNombre] = useState("");

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUsuario(currentUser);

        // Consultar la colección 'usuarios' donde el campo 'uid' coincida con el uid del usuario autenticado
        const usuariosRef = collection(db, "usuarios");
        const q = query(usuariosRef, where("uid", "==", currentUser.uid));

        // Obtener los documentos que coinciden con la consulta
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // Si hay resultados, obtenemos el nombre del usuario
          querySnapshot.forEach((doc) => {
            setNombre(doc.data().usuario);
          });
        } else {
          console.log("No se encontró el usuario en Firestore");
        }
      } else {
        setUsuario(null);
        setNombre("");
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const cerrarSesion = () => {
    auth
      .signOut(auth)
      .then(() => {
        console.log("Sesión cerrada");
      })
      .catch((error) => {
        console.log("Error cerrando sesión", error);
      });
  };

  return (
    <div className="nav">
      <div className="logo">
        <h1>
          <Link to="/" className="h1nav">
            AMIGOS DE LA AMISTAD
          </Link>
        </h1>
      </div>
      <ul className="nav-links">
        <li>
          <Link to="/ranking">Ranking</Link>
        </li>
        <li>
          <Link to="/votar">Votar hoy</Link>
        </li>
        <ul className="nav-user">
          <li className="usuario-link">
            {usuario ? (
              // Si hay un usuario autenticado, muestra su nombre y un botón de salit
              <>
                <Link to="/perfil">Hola, {nombre}. </Link>

                <Link onClick={cerrarSesion} to="/">
                  Salir
                </Link>
              </>
            ) : (
              // Si no hay usuario autenticado, muestra el botón de login
              <Link to="/login">Login / Registro</Link>
            )}
          </li>
        </ul>
      </ul>
    </div>
  );
}

export default Menu;
