import React, { useState, useEffect } from "react";
import Menu from "../componentes/menu";
import { db, auth } from "../firebase_conf";
import { collection, query, getDocs, where, addDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

function Votar() {
  const [usuarios, setUsuarios] = useState([]);
  const [votoPDisponible, setVotoPDisponible] = useState(true);
  const [votoNDisponible, setVotoNDisponible] = useState(true);

  const [votadoN, setVotadoN] = useState("x");
  const [votadoP, setVotadoP] = useState("x");

  const clasesMSG = ["verde", "rojo"];
  const [msg, setMsg] = useState("");
  const [num, setNum] = useState(0);

  const navigate = useNavigate();

  const meses = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];

  // Obtenemos los documentos de la coleccion usuarios y los votos de hoy
  useEffect(() => {
    //Comprobamos si el usuario esta logueado
    if (!auth.currentUser) {
      navigate("/login");
      return;
    }

    const obtenerUsuarios = async () => {
      const q = query(collection(db, "usuarios"));
      const querySnapshot = await getDocs(q);
      let usuariosTemporal = [];
      querySnapshot.forEach((doc) => {
        usuariosTemporal.push(doc.data());
      });

      //Quitamos al usuario actual de la lista
      usuariosTemporal = usuariosTemporal.filter(
        (usuario) => usuario.uid !== auth.currentUser.uid
      );
      //TODO: Quitar al usuario actual de la lista

      // Ordenamos los usuarios por campo usuario
      usuariosTemporal.sort((a, b) => {
        if (a.usuario < b.usuario) {
          return -1;
        }
        if (a.usuario > b.usuario) {
          return 1;
        }
        return 0;
      });

      setUsuarios(usuariosTemporal);
    };

    const obtenerVotosDeHoy = async () => {
      // Comprobamos si el usuario ya ha votado hoy
      const votosRef = collection(db, "votos");

      // Consulta para encontrar los votos del usuario actual
      const votosDelUsuarioQuery = query(
        votosRef,
        where("votante", "==", auth.currentUser.uid),
        where("mes", "==", new Date().getMonth() + 1),
        where("anio", "==", new Date().getFullYear()),
        where("dia", "==", new Date().getDate())
      );

      const votosDelUsuario = await getDocs(votosDelUsuarioQuery);

      let array = votosDelUsuario.docs.map((voto) => voto.data());

      // Comprobamos si el usuario ya ha votado hoy
      array.forEach((voto) => {
        if (voto.votante === auth.currentUser.uid) {
          if (voto.tipo === "mejor") {
            setVotoPDisponible(false);
            setVotadoP(voto.idusuario);
          } else if (voto.tipo === "peor") {
            setVotoNDisponible(false);
            setVotadoN(voto.idusuario);
          }
        }
      });
    };

    obtenerUsuarios();
    obtenerVotosDeHoy();
  }, []);

  const votar = async (usuario, tipo) => {
    setMsg("");

    try {
      // Comprobamos si el usuario ya ha votado hoy
      const votosRef = collection(db, "votos");

      // Consulta para encontrar los votos del usuario actual
      const votosDelUsuarioQuery = query(
        votosRef,
        where("votante", "==", auth.currentUser.uid),
        where("mes", "==", new Date().getMonth() + 1),
        where("anio", "==", new Date().getFullYear()),
        where("dia", "==", new Date().getDate())
      );

      const votosDelUsuario = await getDocs(votosDelUsuarioQuery);

      const arrayVotos = votosDelUsuario.docs.map((voto) => voto.data());

      //Recorremos los votos del usuario actual para ver si intenta votar lo mismo
      arrayVotos.forEach((voto) => {
        if (voto.voto === tipo) {
          setNum(1);
          setMsg("Ya has votado ");
          return;
        }
      });

      // Comprobamos si vota al mismo en ambos votos
      if (tipo === "mejor" && votadoN === usuario) {
        setNum(1);
        setMsg("No puedes votar al mismo usuario en ambos votos");
        return;
      } else if (tipo === "peor" && votadoP === usuario) {
        setNum(1);
        setMsg("No puedes votar al mismo usuario en ambos votos");
        return;
      }

      // Si no ha votado, votamos
      //Agregamos documento a la coleccion votos
      const nuevoVoto = {
        votante: auth.currentUser.uid,
        idusuario: usuario,
        tipo: tipo,
        dia: new Date().getDate(),
        mes: new Date().getMonth() + 1,
        anio: new Date().getFullYear(),
      };

      addDoc(votosRef, nuevoVoto);

      if (tipo === "mejor") {
        setVotadoP(usuario);
        setVotoPDisponible(false);
      } else if (tipo === "peor") {
        setVotadoN(usuario);
        setVotoNDisponible(false);
      }

      setNum(0);
      setMsg("Voto realizado");
    } catch (error) {
      console.error("Error al votar:", error);
      setMsg("Error al votar");
      setNum(1);
    }
  };

  return (
    <div className="Padre">
      <div className="ContenedorNav">
        <Menu></Menu>
      </div>

      <div className="Cuerpo">
        <div className="ContenedorVotar">
          <h2 className="titulo1 centro">VOTO DIARIO</h2>
          <p className="centro">
            Voto del {new Date().getDate()} de {meses[new Date().getMonth()]} de{" "}
            {new Date().getFullYear()}
          </p>
          <p className="centro">
            Votos disponibles:{" "}
            {votoPDisponible && votoNDisponible ? "Bueno y malo" : ""}{" "}
            {votoNDisponible && !votoPDisponible ? "Malo" : ""}{" "}
            {votoPDisponible && !votoNDisponible ? "Bueno" : ""}
          </p>

          <div className="SeccionVotar">
            <p className={clasesMSG[num]}>{msg}</p>

            <table className="TablaVotar">
              <thead>
                <tr>
                  <th>Usuario</th>
                  <th>Voto</th>
                </tr>
              </thead>

              <tbody>
                {usuarios.map((usuario) => (
                  <tr key={usuario.uid}>
                    <td>{usuario.usuario}</td>

                    <td>
                      {votoPDisponible ? (
                        <button
                          className="VotoMejor BotonVoto"
                          onClick={() => votar(usuario.uid, "mejor")}
                        >
                          Bueno
                        </button>
                      ) : (
                        <button
                          className="VotoMejor BotonVoto disabled"
                          disabled
                        >
                          Bueno
                        </button>
                      )}
                      {votoNDisponible ? (
                        <button
                          className="VotoPeor BotonVoto"
                          onClick={() => votar(usuario.uid, "peor")}
                        >
                          Malo
                        </button>
                      ) : (
                        <button
                          className="VotoPeor BotonVoto disabled"
                          disabled
                        >
                          Malo
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Votar;
