import React, { useState, useEffect } from "react";
import Menu from "../componentes/menu";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase_conf";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";

function Perfil() {
  const navigate = useNavigate();
  const [admin, setAdmin] = useState(false);
  const [nombreUsuario, setNombreUsuario] = useState("");
  const [email, setEmail] = useState("");
  const [votoPUsadoHoy, setVotoPUsadoHoy] = useState("No");
  const [votosPDados, setVotosPDados] = useState("");
  const [votoNUsadoHoy, setVotoNUsadoHoy] = useState("No");
  const [votosNDados, setVotosNDados] = useState("");
  const [mes, setMes] = useState("");
  const [anio, setAnio] = useState("");
  const [errorGenerar, setErrorGenerar] = useState("");

  const [adminLista, setAdminLista] = useState([]);

  // Mandamos al usuario al login si no está logueado
  useEffect(() => {
    if (auth.currentUser == null) {
      navigate("/login");
      return;
    }
  }, [navigate]);

  //Traer datos del usuario de la coleccion usuarios
  useEffect(() => {
    const usuariosRef = collection(db, "usuarios");
    const usuariosQuery = query(
      usuariosRef,
      where("uid", "==", auth.currentUser.uid)
    );
    getDocs(usuariosQuery).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setNombreUsuario(doc.data().usuario);
        setEmail(auth.currentUser.email);

        if (doc.data().admin !== undefined) {
          if (doc.data().admin) {
            setAdmin(true);
          }
        }
      });
    });
  }, []);

  useEffect(() => {
    const obtenerUsuarios = async () => {
      //Obtenemos todos los usuarios de la colección usuarios y los guardamos en un array junto a su codigo de registro de la colección codigosRegistro
      const usuariosRef = collection(db, "usuarios");
      const usuariosQuery = query(usuariosRef);
      const codigosRegistroRef = collection(db, "codigosRegistro");
      const codigosRegistroQuery = query(codigosRegistroRef);

      const docsUsuarios = await getDocs(usuariosQuery);
      let arrayUsuarios = docsUsuarios.docs.map((usuario) => usuario.data());

      const docsCodigos = await getDocs(codigosRegistroQuery);
      let arrayCodigos = docsCodigos.docs.map((codigo) => codigo.data());

      //Asociamos en un nuevo array el idUsuario de la coleccion codigosRegistro con el uid de la coleccion usuarios,
      //tambien añadimos el campo email de la coleccion usuarios

      let arrayUsuariosCodigos = [];

      arrayUsuarios.forEach((usuario) => {
        arrayCodigos.forEach((codigo) => {
          if (usuario.uid === codigo.idusuario) {
            let usuarioCodigo = {
              usuario: usuario.usuario,
              email: usuario.email,
              codigo: codigo.codigo,
            };
            arrayUsuariosCodigos.push(usuarioCodigo);
          }
        });
      });

      //Ordenamos el array por el campo usuario
      arrayUsuariosCodigos.sort((a, b) => {
        if (a.usuario < b.usuario) {
          return -1;
        }
        if (a.usuario > b.usuario) {
          return 1;
        }
        return 0;
      });

      setAdminLista(arrayUsuariosCodigos);
    };

    obtenerUsuarios();
  }, []);

  //Traer datos de los votos del usuario
  useEffect(() => {
    // Referencia a la colección votos
    const votosRef = collection(db, "votos");

    // Consulta para encontrar los votos del usuario actual
    const votosDelUsuarioQuery = query(
      votosRef,
      where("votante", "==", auth.currentUser.uid),
      where("mes", "==", new Date().getMonth() + 1)
    );

    // Votos del usuario
    getDocs(votosDelUsuarioQuery).then((querySnapshot) => {
      let votosPDados = 0;
      let votosNDados = 0;

      querySnapshot.forEach((doc) => {
        const voto = doc.data();
        if (voto.tipo === "mejor") {
          votosNDados++;
          if (
            voto.dia === new Date().getDate() &&
            voto.mes === new Date().getMonth() + 1 &&
            voto.anio === new Date().getFullYear()
          ) {
            setVotoNUsadoHoy("Si");
          }
        } else {
          votosPDados++;
          if (
            voto.dia === new Date().getDate() &&
            voto.mes === new Date().getMonth() + 1 &&
            voto.anio === new Date().getFullYear()
          ) {
            setVotoPUsadoHoy("Si");
          }
        }
      });

      setVotosNDados(votosNDados);
      setVotosPDados(votosPDados);
    });
  }, []);

  return (
    <div className="Padre">
      <div className="ContenedorNav">
        <Menu></Menu>
      </div>

      <div className="Cuerpo">
        {admin && <div className="margenPerfil"> </div>}

        <div className="ContenedorPerfil">
          <h1 className="titulo1 centro">MI PERFIL</h1>

          <div className="SeccionPerfil">
            <h2>Datos usuario</h2>
            <p className="CampoPerfil">Nombre: {nombreUsuario}</p>
            <p className="CampoPerfil">E-mail: {email}</p>
          </div>

          <div className="espacioperfil"></div>

          <div className="SeccionPerfil">
            <h2 className="mas">Votos al mejor del mes</h2>
            <p className="CampoPerfil">¿He votado hoy? {votoNUsadoHoy}</p>
            <p className="CampoPerfil">Votos dados este mes: {votosNDados}</p>
          </div>

          <div className="espacioperfil"></div>
          <div className="SeccionPerfil">
            <h2 className="menos">Votos al peor del mes</h2>
            <p className="CampoPerfil">¿He votado hoy? {votoPUsadoHoy}</p>
            <p className="CampoPerfil">Votos dados este mes: {votosPDados}</p>
          </div>
        </div>

        <div className="espaciomini"></div>

        {admin && (
          <div className="ContenedorPerfil">
            <div className="SeccionPerfil">
              <h2>Generar resultados</h2>

              <div className="ContenedorFecha">
                <select
                  className="InputPerfil InputFecha"
                  onChange={(e) => setMes(e.target.value)}
                >
                  <option value="01">Enero</option>
                  <option value="02">Febrero</option>
                  <option value="03">Marzo</option>
                  <option value="04">Abril</option>
                  <option value="05">Mayo</option>
                  <option value="06">Junio</option>
                  <option value="07">Julio</option>
                  <option value="08">Agosto</option>
                  <option value="09">Septiembre</option>
                  <option value="10">Octubre</option>
                  <option value="11">Noviembre</option>
                  <option value="12">Diciembre</option>
                </select>

                <select
                  className="InputPerfil InputFecha EspacioFechas"
                  onChange={(e) => setAnio(e.target.value)}
                >
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                  <option value="2029">2029</option>
                  <option value="2030">2030</option>
                </select>
              </div>
              <button
                className="BotonActualizar BotonGenerar"
                onClick={async () => {
                  try {
                    setErrorGenerar("");

                    // Referencia a la colección 'votos'
                    const votosRef = collection(db, "votos");

                    // Crear consulta filtrando por mes y año
                    const votosQuery = query(
                      votosRef,
                      where("mes", "==", parseInt(mes, 10)),
                      where("anio", "==", parseInt(anio, 10))
                    );

                    //Para guardar los votos:
                    const conteoUsuariosBueno = {};
                    const conteoUsuariosMalo = {};

                    // Obtener los votos
                    const votos = await getDocs(votosQuery);

                    //Filtramos los votos por bueno y malo
                    votos.forEach((doc) => {
                      const voto = doc.data();
                      if (voto.tipo === "mejor") {
                        if (conteoUsuariosBueno[voto.idusuario] === undefined) {
                          conteoUsuariosBueno[voto.idusuario] = 0;
                        }
                        conteoUsuariosBueno[voto.idusuario]++;
                      } else {
                        if (conteoUsuariosMalo[voto.idusuario] === undefined) {
                          conteoUsuariosMalo[voto.idusuario] = 0;
                        }
                        conteoUsuariosMalo[voto.idusuario]++;
                      }
                    });

                    // Encontrar el mejor del mes
                    let maxVotosBueno = 0;
                    let usuarioConMasVotosBueno = null;

                    for (const usuario in conteoUsuariosBueno) {
                      if (conteoUsuariosBueno[usuario] > maxVotosBueno) {
                        maxVotosBueno = conteoUsuariosBueno[usuario];
                        usuarioConMasVotosBueno = usuario;
                      }
                    }

                    // Encontrar el peor del mes
                    let maxVotosMalo = 0;
                    let usuarioConMasVotosMalo = null;

                    for (const usuario in conteoUsuariosMalo) {
                      if (conteoUsuariosMalo[usuario] > maxVotosMalo) {
                        maxVotosMalo = conteoUsuariosMalo[usuario];
                        usuarioConMasVotosMalo = usuario;
                      }
                    }

                    if (
                      usuarioConMasVotosBueno === null ||
                      usuarioConMasVotosMalo === null
                    ) {
                      setErrorGenerar(
                        "No hay votos suficientes para generar resultados."
                      );
                      return;
                    }

                    // Array votos
                    // Quitamos a usuarioConMasVotosBueno de la lista conteoUsuariosBueno
                    delete conteoUsuariosBueno[usuarioConMasVotosBueno];
                    //Quitamos a usuarioConMasVotosMalo de la lista conteoUsuariosMalo
                    delete conteoUsuariosMalo[usuarioConMasVotosMalo];

                    // Ordenamos las listas por el numero de votos de mayor a menor
                    const listaOrdenadaBueno = Object.entries(
                      conteoUsuariosBueno
                    ).sort(([, a], [, b]) => b - a);

                    const listaOrdenadaMalo = Object.entries(
                      conteoUsuariosMalo
                    ).sort(([, a], [, b]) => b - a);

                    // Pasamos los votos a un array de objetos para guardar en la coleccion
                    const votosBuenos = [];
                    const votosMalos = [];

                    listaOrdenadaBueno.forEach((voto) => {
                      votosBuenos.push({
                        idusuario: voto[0],
                        votos: voto[1],
                      });
                    });

                    listaOrdenadaMalo.forEach((voto) => {
                      votosMalos.push({
                        idusuario: voto[0],
                        votos: voto[1],
                      });
                    });

                    // Guardamos en la coleccion

                    const rankingRef = collection(db, "ranking");

                    const nuevoRanking = {
                      idmejor: usuarioConMasVotosBueno,
                      idpeor: usuarioConMasVotosMalo,
                      votosmejor: maxVotosBueno,
                      votospeor: maxVotosMalo,
                      mes: parseInt(mes, 10),
                      anio: parseInt(anio, 10),
                      votosBuenos: votosBuenos,
                      votosMalos: votosMalos,
                    };

                    // Comprobamos si ya hay un ranking para este mes

                    const rankingQuery = query(
                      rankingRef,
                      where("mes", "==", parseInt(mes, 10)),
                      where("anio", "==", parseInt(anio, 10))
                    );

                    const ranking = await getDocs(rankingQuery);

                    if (!ranking.empty) {
                      setErrorGenerar(
                        "Ya hay un ranking generado para este mes."
                      );
                      return;
                    }

                    await addDoc(rankingRef, nuevoRanking);

                    setErrorGenerar("Resultados generados correctamente.");
                  } catch (error) {
                    console.error("Error generando resultados", error);
                    setErrorGenerar("Error generando resultados.");
                  }
                }}
              >
                Generar
              </button>
              <p className="ErrorGenerar">{errorGenerar}</p>
            </div>

            <div className="SeccionPerfil margenAdmin">
              <h2>Usuarios</h2>
              {
                //Mostramos los usuarios con su email y codigo de registro
                adminLista.map((usuario, index) => (
                  <div key={index}>
                    <p className="CampoPerfil">
                      {usuario.usuario} - {usuario.email} - {usuario.codigo}
                    </p>
                  </div>
                ))
              }
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Perfil;
