import React from "react";
import { Link } from "react-router-dom";
import Menu from "../componentes/menu";

function Inicio() {
  return (
    <div className="Padre">
      <div className="ContenedorNav">
      <Menu></Menu>
      </div>

      <div className="Cuerpo">
        <div className="espacio"></div>

        <div className="SeccionGlich">
          <div className="hero-container">
            <h2 className="titulo1">¿SERÁS EL BUENO DE ESTE MES?</h2>
            <div className="environment"></div>
            <h2 className="hero glitch layers" data-text="¿O QUIZÁS EL MALO?">
              <span>¿O QUIZÁS EL MALO?</span>
            </h2>
          </div>
        </div>
        <Link className="BotonRanking" to="/ranking">Ver el Ranking</Link>
      </div>
    </div>
  );
}

export default Inicio;
