import React, { useState, useEffect } from "react";
import Menu from "../componentes/menu";
import { auth, db } from "../firebase_conf";
import { collection, query, where, getDocs, updateDoc, doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile
} from "firebase/auth";

function Login() {
  const navigate = useNavigate();

  const [emailLogin, setEmailLogin] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");
  const [errorLogin, setErrorLogin] = useState("");

  const [codigoRegistro, setCodigoRegistro] = useState("");
  const [nombreRegistro, setNombreRegistro] = useState("");
  const [emailRegistro, setEmailRegistro] = useState("");
  const [passwordRegistro, setPasswordRegistro] = useState("");
  const [errorRegistro, setErrorRegistro] = useState("");

  // Mandamos al usuario al perfil si está logueado
  useEffect(() => {
    if (auth.currentUser !== null) {
      navigate("/perfil");
    }
  }, [navigate]);

  return (
    <div className="Padre">
      <div className="ContenedorNav">
      <Menu></Menu>
      </div>

      <div className="Cuerpo">

        <div className="ContenedorLogin">
          <h2>Inicio de sesión</h2>
          <input
            className="campoLogin"
            type="email"
            placeholder="Correo electrónico"
            value={emailLogin}
            onChange={(e) => setEmailLogin(e.target.value)}
          />
          <input
            className="campoLogin"
            type="password"
            placeholder="Contraseña"
            value={passwordLogin}
            onChange={(e) => setPasswordLogin(e.target.value)}
          />
          <button
            className="BotonLogin"
            onClick={async () => {
              try {
                await signInWithEmailAndPassword(
                  auth,
                  emailLogin,
                  passwordLogin
                );
                navigate("/perfil");
              } catch (error) {
                console.error("Error iniciando sesión", error);
                setErrorLogin("Comprueba los datos.");
              }
            }}
          >
            Iniciar sesión
          </button>
          <p className="msgErrorLogin">{errorLogin}</p>
        </div>

        <div className="espaciomini"></div>

        <div className="ContenedorLogin">
          <h2>Registro</h2>
          <input
            className="campoLogin"
            type="text"
            placeholder="Código de registro"
            value={codigoRegistro}
            onChange={(e) => setCodigoRegistro(e.target.value)}
          />
          <input
            className="campoLogin"
            type="text"
            placeholder="Nombre"
            value={nombreRegistro}
            onChange={(e) => setNombreRegistro(e.target.value)}
          />
          <input
            className="campoLogin"
            type="email"
            placeholder="Correo electrónico"
            value={emailRegistro}
            onChange={(e) => setEmailRegistro(e.target.value)}
          />
          <input
            className="campoLogin"
            type="password"
            placeholder="Contraseña"
            value={passwordRegistro}
            onChange={(e) => setPasswordRegistro(e.target.value)}
          />
          <button
            className="BotonLogin"
            onClick={async () => {
              try {
                // Comprobamos que el código de registro es correcto
                const q = query(
                  collection(db, "codigosRegistro"),
                  where("codigo", "==", codigoRegistro)
                );
                const querySnapshot = await getDocs(q);

                if (querySnapshot.empty) {
                  setErrorRegistro("Código de registro incorrecto.");
                  return;
                }

                const docData = querySnapshot.docs[0].data();
                const docRef = querySnapshot.docs[0].ref;

                if (docData.usado) {
                  setErrorRegistro("Código de registro ya usado.");
                  return;
                }

                // Si el código no se ha usado, registramos al usuario
                const userCredential = await createUserWithEmailAndPassword(
                  auth,
                  emailRegistro,
                  passwordRegistro
                );

                const user = userCredential.user;

                // Marcamos el código como usado y guardamos el usuario
                await updateDoc(docRef, {
                  idusuario: user.uid,
                  usado: true,
                });

                // Guardamos el nombre del usuario en el displayName
                await updateProfile(user, {
                  displayName: nombreRegistro,
                });

                // Guardamos el uid en la colección usuarios creando un nuevo documento con el campo uid
                await setDoc(doc(db, "usuarios", user.uid), {
                    uid: user.uid,
                    email: emailRegistro,
                    usuario: nombreRegistro,
                  });

                navigate("/perfil");
              } catch (error) {
                console.error("Error al registrarse", error);
                setErrorRegistro("Comprueba los datos.");
              }
            }}
          >
            Registrarse
          </button>
          <p className="msgErrorLogin">{errorRegistro}</p>
        </div>
      </div>
    </div>
  );
}

export default Login;
