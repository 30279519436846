import React, { useState, useEffect } from "react";
import Menu from "../componentes/menu";
import { db } from "../firebase_conf";
import { collection, query, getDocs } from "firebase/firestore";

function Ranking() {
  const meses = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];

  // Obtenemos los documentos de la coleccion ranking
  const [ranking, setRanking] = useState([]);

  useEffect(() => {
    const obtenerRanking = async () => {
      const q = query(collection(db, "ranking"));
      const querySnapshot = await getDocs(q);
      let rankingTemporal = [];
      querySnapshot.forEach((doc) => {
        rankingTemporal.push(doc.data());
      });

      //obtenemos coleccion usuarios
      const q2 = query(collection(db, "usuarios"));
      const querySnapshot2 = await getDocs(q2);
      let usuarios = [];
      querySnapshot2.forEach((doc) => {
        usuarios.push(doc.data());
      });

      //Sustituimos los id de los usuarios por sus nombres
      rankingTemporal.forEach((elemento) => {
        usuarios.forEach((usuario) => {
          if (elemento.idmejor === usuario.uid) {
            elemento.idmejor = usuario.usuario;
          }
          if (elemento.idpeor === usuario.uid) {
            elemento.idpeor = usuario.usuario;
          }
        });
      });

      //Sustituimos nombres en los arrays, si hay.
      rankingTemporal.forEach((elemento) => {
        if (elemento.votosBuenos !== undefined) {
          elemento.votosBuenos.forEach((voto) => {
            usuarios.forEach((usuario) => {
              if (voto.idusuario === usuario.uid) {
                //Cambiamos el id por el nombre
                voto.idusuario = usuario.usuario;
              }
            });
          });
        }
      });

      rankingTemporal.forEach((elemento) => {
        if (elemento.votosMalos !== undefined) {
          elemento.votosMalos.forEach((voto) => {
            usuarios.forEach((usuario) => {
              if (voto.idusuario === usuario.uid) {
                //Cambiamos el id por el nombre
                voto.idusuario = usuario.usuario;
              }
            });
          });
        }
      });

      setRanking(rankingTemporal);

      rankingTemporal.sort((a, b) => {
        if (a.anio === b.anio) {
          return b.mes - a.mes; // Si el año es el mismo, se ordena por mes
        }
        return b.anio - a.anio; // Si el año es diferente, se ordena por año
      });
    };
    obtenerRanking();
  }, []);

  return (
    <div className="Padre">
      <div className="ContenedorNav">
        <Menu></Menu>
      </div>

      <div className="Cuerpo">
        <div className="ContenedorRanking MargenMovil">
          <h1 className="titulo1">RANKING</h1>
          {ranking.map((elemento) => (
            <div className="ContenedorTabla">
              <h2 className="TituloFecha">
                Mes de {meses[elemento.mes - 1]} de {elemento.anio}
              </h2>
              <table className="TablaRanking">
                <thead>
                  <tr>
                    <th></th>
                    <th>Nombre</th>
                    <th>Votos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>El bueno del mes</th>
                    <td>{elemento.idmejor}</td>
                    <td>{elemento.votosmejor}</td>
                  </tr>
                  <tr>
                    <th>El malo del mes</th>
                    <td>{elemento.idpeor}</td>
                    <td>{elemento.votospeor}</td>
                  </tr>
                </tbody>
              </table>

              {elemento.votosBuenos !== undefined && (
                <div>
                  <h3 className="titulo3 marginTop">Votos al bueno del mes</h3>
                  <table className="TablaRanking">
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Votos</th>
                      </tr>
                    </thead>
                    <tbody>
                      {elemento.votosBuenos.map((voto) => (
                        <tr>
                          <td>{voto.idusuario}</td>
                          <td>{voto.votos}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {elemento.votosMalos !== undefined && (
                <div>
                  <h3 className="titulo3 marginTop">Votos al malo del mes</h3>
                  <table className="TablaRanking">
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Votos</th>
                      </tr>
                    </thead>
                    <tbody>
                      {elemento.votosMalos.map((voto) => (
                        <tr>
                          <td>{voto.idusuario}</td>
                          <td>{voto.votos}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Ranking;
