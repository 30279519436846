import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDEeEZmCCToHnEXkK6JWKPVEapxADM7c88",
  authDomain: "amigos-de-la-amistad.firebaseapp.com",
  projectId: "amigos-de-la-amistad",
  storageBucket: "amigos-de-la-amistad.firebasestorage.app",
  messagingSenderId: "120065604442",
  appId: "1:120065604442:web:f9d969c83cb4b95ed3d163",
  measurementId: "G-QLLP1ZCNZ4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
